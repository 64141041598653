import { MutationTree } from "vuex";
import { AuthState, User } from "./state";

const mutations: MutationTree<AuthState> = {
  SET_USER(state, newVal: User) {
    state.user = newVal;
  },
  SET_AUTH_STATE(state, val: 'authenticated' | 'unauthenticated') {
    state.authState = val;
  },
};

export default mutations;
