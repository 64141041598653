var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-menu',{ref:"menuDateStart",attrs:{"close-on-content-click":false,"return-value":_vm.dateStart,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateStart=$event},"update:return-value":function($event){_vm.dateStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.r_required_string, _vm.validDateRange]},on:{"change":function($event){_vm.handleChange();
            _vm.check_max_date_range();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha Inicio "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}],null,true),model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateStart),callback:function ($$v) {_vm.menuDateStart=$$v},expression:"menuDateStart"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"scrollable":"","min":_vm.dateRange.min,"max":_vm.dateRange.max},on:{"change":function($event){_vm.handleChange();
          _vm.check_max_date_range();}},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDateStart = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuDateStart.save(_vm.dateStart)}}},[_vm._v("OK")])],1)],1),_c('v-menu',{ref:"menuDateEnd",attrs:{"close-on-content-click":false,"return-value":_vm.dateEnd,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateEnd=$event},"update:return-value":function($event){_vm.dateEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","rules":[_vm.r_required_string, _vm.validDateRange]},on:{"change":function($event){_vm.handleChange();
            _vm.check_max_date_range();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha Término "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}],null,true),model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateEnd),callback:function ($$v) {_vm.menuDateEnd=$$v},expression:"menuDateEnd"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"scrollable":"","min":_vm.dateRange.min,"max":_vm.dateRange.max},on:{"change":function($event){_vm.handleChange();
          _vm.check_max_date_range();}},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDateEnd = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuDateEnd.save(_vm.dateEnd)}}},[_vm._v("OK")])],1)],1),_c('v-select',{attrs:{"items":_vm.regions,"rules":[_vm.r_max3, _vm.r_required_list],"multiple":"","chips":"","required":""},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Región "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.selectedRegions),callback:function ($$v) {_vm.selectedRegions=$$v},expression:"selectedRegions"}}),(!_vm.r_mueproesp_disabled)?_c('v-autocomplete',{attrs:{"items":_vm.species,"rules":[
        _vm.r_max5,
        _vm.r_mueproesp_disabled ? function (val) { return true; } : _vm.r_required_list_species ],"multiple":"","chips":"","disabled":_vm.r_mueproesp_disabled || _vm.speciesLoading || _vm.selectedRegions.length === 0,"loading":_vm.speciesLoading,"required":"!r_mueproesp_disabled"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Especie "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(_vm._s(_vm.r_mueproesp_disabled ? "" : "*"))])])]},proxy:true}],null,false,809145682),model:{value:(_vm.selectedSpecies),callback:function ($$v) {_vm.selectedSpecies=$$v},expression:"selectedSpecies"}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.fisheries,"multiple":"","chips":"","disabled":_vm.fisheriesLoading,"loading":_vm.fisheriesLoading},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pesquería "),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(Opcional)")])]},proxy:true}]),model:{value:(_vm.selectedFisheries),callback:function ($$v) {_vm.selectedFisheries=$$v},expression:"selectedFisheries"}},[_c('v-list-item',{staticClass:"pl-4 py-2",attrs:{"slot":"prepend-item"},on:{"click":_vm.toggleFisheries},slot:"prepend-item"},[_c('v-list-action',[_c('v-icon',{attrs:{"color":_vm.selectedFisheries.length > 0 ? 'indigo-darken-4' : ''}},[_vm._v(_vm._s(_vm.allFisheriesIcon))])],1),_c('v-list-item-title',{staticClass:"pl-8"},[_vm._v("Seleccionar todas")]),_c('v-divider',{staticClass:"mt-2"})],1)],1),_c('v-card-actions',{staticClass:"mt-6 pl-0"},[_c('v-btn',{attrs:{"disabled":!_vm.formValid,"color":"primary"},on:{"click":_vm.search}},[_vm._v("BUSCAR")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }