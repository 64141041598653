import { MutationTree } from "vuex";
import { FiltersSelected } from "./state";

const mutations: MutationTree<FiltersSelected> = {
    MUTACION1 (state, newVal: string) {
        state.valor = newVal
     },
    SAVEREQUEST (state, postRequest) {
        state.postRequest = postRequest
    },
    SAVESELECTED (state, selected) {
        state.selected = selected
    },
    SETHEADER (state, header) {
        state.header = header
    }
  }

export default mutations