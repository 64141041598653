import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#0b55a1",
        //secondary: "#00478d",
        "main-background": "#fffefa",
        primary: "#22577E",
        secondary: "#1B6D97",
        tertiary: "#67B9C8",
        success: colors.green.darken1,
      },
    },
    dark: false,
  },
});
