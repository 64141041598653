export type FiltersSelected = {
  valor: string; // TODO: remover
  header: string;
  selected: {
    dateStart: string;
    dateEnd: string;
    selectedSpecies: string[];
    selectedRegions: string[];
    selectedOrigenViaje: string[];
    selectedActividadViaje: string[];
    selectedLugarMuestreo: string[];
  };
  postRequest: {
    dateStart: string;
    dateEnd: string;
    species: string[];
    regions: string[];
    actividadViaje: string[];
    origenViaje: string[];
    lugarMuestreo: string[];
  };
};

const state = (): FiltersSelected => ({
  valor: "",
  header: "",
  selected: {
    dateStart: "",
    dateEnd: "",
    selectedSpecies: [],
    selectedRegions: [],
    selectedOrigenViaje: [],
    selectedActividadViaje: [],
    selectedLugarMuestreo: [],
  },
  postRequest: {
    dateStart: "",
    dateEnd: "",
    species: [],
    regions: [],
    actividadViaje: [],
    origenViaje: [],
    lugarMuestreo: [],
  },
});

export default state;
