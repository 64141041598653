export const awsconfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.VUE_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_WEB_CLIENT_ID,
  },
};

console.log(process.env.VUE_APP_REGION,
  process.env.VUE_APP_REGION,
  process.env.VUE_APP_USER_POOL_ID,
  process.env.VUE_APP_WEB_CLIENT_ID,)