export const emailRules = [
        (v: string) => !!v || "Campo obligatorio.",
        (v: string) => /.+@.+\..+/.test(v) || "Email debe ser válido."
];

export const passwordRules = [
    (v: string) => !!v || "Campo obligatorio.",
    (v: string) => /.{8,}/.test(v) || "Contraseña debe poseer como mínimo 8 caracteres.",
    (v: string) => /(?=.*?[A-Z])/.test(v) || "Contraseña debe tener al menos 1 letra mayúscula.",
    (v: string) => /(?=.*?[a-z])/.test(v) || "Contraseña debe tener al menos 1 letra minúscula.",
    (v: string) => /(?=.*?[0-9])/.test(v) || "Contraseña debe tener al menos 1 dígito.",
    (v: string) => /(?=.*?[#?!@$%^&*-])/.test(v) || "Contraseña debe tener al menos 1 caracter especial.",
];

export const commonRules = [
    (v: string) => !!v || "Campo obligatorio.",
]

export const codeRules = [
    (v: string) => /[0-9]{6,}/.test(v) || "Código debe tener 6 dígitos.",
];