import state from "./state";
import mutations from "./mutations";

const auth = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: {},
  getters: {},
};

export default auth;
