export const errorMessages = {
    generic: 'Error: Por favor inténtalo más tarde.',
    repeatedEmail: 'Tu registro no pudo ser completado porque ya existe un usuario con el mismo correo. Por favor vuelve a intentarlo con otra dirección o ingresa con tu usuario ya registrado.',
    signUpConfirmation: 'La confirmación de tu registro no pudo realizarse.',
    resendConfirmationCode: 'No pudimos enviar el código de verificación al correo especificado, por favor vuelve a intentarlo.',
    wrongConfirmationCode: 'Código de confirmación incorrecto. Por favor vuelve a intentarlo.',
    wrongSignInCredentials: 'Nombre de usuario y/o contraseña incorrectos. Por favor verifica que ambos estén correctos y vuelve a intentarlo.',
    disabledUser: 'Tu usuario está correctamente registrado, pero se encuentra temporalmente deshabilitado. Un administrador revisará tu solicitud y te habilitará a la brevedad.',
    changedPassword: 'El cambio de tu contraseña no pudo realizarse.',
    wrongChangePasswordCode: 'Tu contraseña no pudo ser cambiada porque ingresaste un código de verificación inválido. Por favor vuelve a intentarlo.',
};
export const successMessages = {
    signUp: 'Tu registro fue realizado exitosamente.',
    signUpConfirmation: 'La confirmación de tu registro fue realizada exitosamente.',
    resendConfirmationCode: 'Código de confirmación enviado exitosamente.',
    changedPassword: 'Tu contraseña ha sido cambiada exitosamente.',
    signIn: 'Has ingresado correctamente.',
    signOut: 'Has cerrado tu sesión.'
};