import { ActionTree } from "vuex";
import { FiltersSelected } from "./state";

const actions: ActionTree<FiltersSelected, unknown> = {
  funcion1({ commit }, valorNuevo: string) {
    commit("MUTACION1", valorNuevo);
  },
  savePostRequest({ commit }, postRequest) {
    commit("SAVEREQUEST", postRequest);
  },
  saveSelected({ commit }, selected) {
    commit("SAVESELECTED", selected);
  },
  setHeader({ commit }, header: string) {
    commit("SETHEADER", header);
  },
};

export default actions;
