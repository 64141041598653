function max3(obj: string[]) {
    if (obj.length > 3) {
        return 'Máximo de 3 elementos.'
    } else {
        return true
    }
}

function max5(obj: string[]) {
    if (obj.length > 5) {
        return 'Máximo de 5 elementos.'
    } else {
        return true
    }
}

function required_list(obj: string[]) {
    if (obj.length === 0) {
        return 'Campo Obligatorio. Seleccione al menos uno.'
    } else {
        return true
    }
}

function required_string(obj: string) {
    if (!obj) {
        return 'Campo Obligatorio.'
    } else {
        return true
    }
}

function date_range_max(date1: string, date2: string, maxSpan: number = 316440000000) {
    // maxSpan = max time dif in milisecs. 10*365.25*24*60*60*1000
    if (Math.abs(Date.parse(date2) - Date.parse(date1)) > maxSpan) {
        return 'Máximo 10 años de diferencia.' //+ Math.abs(Date.parse(date2) - Date.parse(date1))
    } else {
        return true
    }
}


export { max3, max5, required_list, required_string, date_range_max }