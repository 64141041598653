import Vue from "vue";
import Vuex from "vuex";
import filters from "./filters";
import auth from "./auth";
import { FiltersSelected } from "./filters/state";
import { AuthState } from "./auth/state";

Vue.use(Vuex);

export interface IRootState {
  filters: FiltersSelected;
  auth: AuthState;
}

export default new Vuex.Store({
  modules: {
    filters: filters,
    auth: auth,
  },
});
