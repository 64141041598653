export type User = {
  email: string;
  name: string;
};
export type AuthState = {
  user: User;
  authState: 'authenticated' | 'unauthenticated';
};

export default (): AuthState => ({
  user: {
    name: "",
    email: "",
  },
  authState: "unauthenticated",
});
