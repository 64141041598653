import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/vue/pages/home.vue";
import Pnbit from "@/vue/pages/pnbit.vue";
import Pnmuebio from "@/vue/pages/pnmuebio.vue";
import Pnmuelon from "@/vue/pages/pnmuelon.vue";
import Pnmueproesp from "@/vue/pages/pnmueproesp.vue";
Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home },
  { path: "/bit", component: Pnbit },
  { path: "/muebio", component: Pnmuebio },
  { path: "/muelon", component: Pnmuelon },
  { path: "/mueproesp", component: Pnmueproesp },
];

const router = new VueRouter({ routes, mode: "history" });

export default router;
