import state from "./state"
import mutations from "./mutations"
import actions from "./actions"

const filters = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    //getters: {  }
  }

export default filters;