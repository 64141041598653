<template>
  <v-app class="noselect">
    <v-app-bar app color="primary">
      <v-container fluid class="px-4 px-md-16 py-0">
        <v-row>
          <v-col cols="12" class="d-flex">
            <div>
              <router-link to="/" class="py-1" tag="div">
                <v-row class="cursor-pointer">
                  <v-col cols="auto">
                    <v-img
                      src="./assets/IFOP-Logo.svg"
                      alt="Logo Instituto de Fomento Pesquero"
                      class="ifop-logo mt-2"
                      :contain="true"
                    ></v-img>
                  </v-col>

                  <v-col class="title-page" cols="auto">
                    <span class="title-page--top"> Datos Pesqueros </span>
                    <span class="title-page--bottom">
                      Desarrollado por Data Observatory
                    </span>
                  </v-col>
                </v-row>
              </router-link>
            </div>

            <v-spacer></v-spacer>

            <div class="pa-0 d-flex align-center">
              <nav class="subheader-nav d-sm-none">
                <v-menu offset-y bottom left :key="index">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="justify-space-between mx-1"
                      dark
                      small
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ linkSelected.label }}
                      <v-icon class="ml-2"> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(link, index) in links">
                      <v-list-item
                        color="success darken-2"
                        :to="link.to"
                        :key="index"
                        v-if="link.to"
                        link
                      >
                        <v-list-item-title>
                          {{ link.label }}
                        </v-list-item-title>
                      </v-list-item>

                      <template v-if="link.children">
                        <v-subheader :key="index">
                          {{ link.label }}
                        </v-subheader>

                        <v-list-item
                          color="success darken-2"
                          v-for="(child, childIndex) in link.children"
                          :key="childIndex"
                          :to="child.to"
                          link
                        >
                          <v-list-item-title class="pl-4">
                            {{ child.label }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </v-menu>
              </nav>
              <nav class="subheader-nav d-none d-sm-block">
                <template v-for="(link, index) in links">
                  <v-btn
                    :key="index"
                    :to="link.to"
                    class="mx-1"
                    dark
                    small
                    text
                    v-if="link.to"
                  >
                    {{ link.label }}
                  </v-btn>

                  <v-menu
                    offset-y
                    bottom
                    left
                    v-if="link.children"
                    :key="index"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="justify-space-between mx-1"
                        dark
                        small
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        Muestreos
                        <v-icon class="ml-2"> mdi-chevron-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        color="success darken-2"
                        v-for="(child, childIndex) in link.children"
                        :key="childIndex"
                        :to="child.to"
                        link
                      >
                        <v-list-item-title>
                          {{ child.label }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </nav>
              <!-- Cognito Auth -->
              <cognito-auth-component
                @authMessage="(msg) => showMessage(msg)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="pb-0 main-background">
      <v-alert
        v-model="alert"
        v-if="authMessage.type !== 'none'"
        :type="authMessage.type"
        dismissible
      >
        {{ authMessage.message }}
      </v-alert>
      <router-view> </router-view>
    </v-main>

    <v-footer color="grey darken-4 white--text">
      <v-container>
        <v-row class="my-6">
          <v-col cols="12" md="6" class="d-flex flex-column align-start">
            <h3 class="text-h6">Instituto de Fomento Pesquero</h3>

            <a
              href="https://www.ifop.cl/"
              target="_blank"
              rel="noreferrer external"
              class="my-4"
            >
              <v-img
                src="./assets/IFOP-Logo.svg"
                alt="Logo Instituto de Fomento Pesquero"
                height="50px"
                class="my-2"
                :contain="true"
              ></v-img>
            </a>

            <a
              class="white--text text-subtitle-1"
              href="https://www.ifop.cl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.ifop.cl
            </a>

            <v-spacer></v-spacer>

            <div class="social-icons">
              <a
                href="https://www.facebook.com/ifopperiodista"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-facebook</v-icon>
              </a>
              <a
                href="https://twitter.com/ifop_periodista"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-twitter</v-icon>
              </a>
              <a
                href="https://www.linkedin.com/in/prensa-ifop-228aa81a7/recent-activity/"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-linkedin</v-icon>
              </a>
              <a
                href="https://www.instagram.com/prensa_ifop/"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-instagram</v-icon>
              </a>
            </div>
          </v-col>

          <v-col class="d-md-none">
            <v-divider dark></v-divider>
          </v-col>

          <v-divider dark vertical class="d-none d-md-inline-flex"></v-divider>

          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column align-start pl-md-9"
          >
            <h3 class="text-h6">Data Observatory</h3>

            <a
              href="https://www.dataobservatory.net"
              target="_blank"
              rel="noreferrer external"
              class="py-4"
            >
              <v-img
                src="./assets/DO-Logo.svg"
                alt="Logo Data Observatory"
                height="50px"
                class="ml-n6"
                :contain="true"
              ></v-img>
            </a>

            <a
              class="white--text text-subtitle-1"
              href="https://www.dataobservatory.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.dataobservatory.net
            </a>

            <v-spacer></v-spacer>

            <div class="social-icons">
              <a
                href="https://twitter.com/DataObsCL"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-twitter</v-icon>
              </a>
              <a
                href="https://www.linkedin.com/company/data-observatory-foundation/"
                target="_blank"
                rel="noreferrer external"
              >
                <v-icon large color="#FFFFFF">mdi-linkedin</v-icon>
              </a>
            </div>
          </v-col>
        </v-row>
        <v-divider dark></v-divider>
        <v-row>
          <v-col class="d-flex justify-center">
            <p id="ifop-copyright">
              © IFOP, CHILE. 2022. Todos los derechos reservados. Prohibida la
              reproducción total o parcial sin autorización escrita del
              propietario.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import { eventBus } from "@/vue/eventBus";
import { Auth } from "aws-amplify";
import CognitoAuthComponent from "./components/auth/cognito_auth.vue";
import SignInForm from "./components/auth/forms/sign_in.vue";

export default Vue.extend({
  name: "App",
  components: { CognitoAuthComponent, SignInForm },
  data: () => {
    return {
      headerTable: "",
      links:[
        {
          label:"Inicio",
          to: "/"
        },
        {
          label:"Datos",
          children:[
            {
              label:"Bitácora",
              to: "/bit"
            },
            {
              label:"Muestreo Biológico",
              to: "/muebio"
            },
            {
              label:"Muestreo de Longitud",
              to: "/muelon"
            },
            {
              label:"Muestreo Proporción de Especies",
              to: "/mueproesp"
            },
          ]
        }
      ],
      dialog: false,
      alert: false,
      authMessage: { type: 'none', message: ''},
    };
  },
  computed:{
    linkSelected(){
      const  links = [
        ...this.links.filter(l => !!l.to),
        ...this.links.filter(l => !!l.children).reduce((acc, l) => {
          return [...acc, ...l.children];
        }, [])
      ]
      return links.find(l => l.to == this.$route.path);
    },
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        this.$store.commit("auth/SET_USER", {
          email: "",
          name: "",
        });
        this.$store.commit("auth/SET_AUTH_STATE", '');
      } catch (error) {
        console.log('Error Signing Out: ', error);
      }
    },
    showMessage(authMessage) {
      this.authMessage = authMessage;
      this.alert = true;
    },
    routeChange(title) {
      this.headerTable = title;
    },
  },
  mounted() {
    eventBus.$on("routeChange", this.routeChange);
  },
});
</script>

<style lang="scss">
#ifop-copyright {
  display: inline-block;
  margin: 1rem;
  color: #dddddd;
}

.social-icons {
  display: flex;
  margin-top: 2em;
  a {
    flex: 0 0 auto;
    margin: 0.5em 2em 0.5em 0;
    text-decoration: none;
  }
}

amplify-authenticator {
  --container-height: 100px;
  --amplify-primary-color: #387eb9;
  --amplify-primary-tint: #2195f3;
  --amplify-primary-shade: #20639b;
  --background-color: #2195f3 !important;
  --amplify-secondary-tint: #263238 !important;
}

.v-dialog {
  box-shadow: none !important;
}

.toast {
  background-color: #2195f3 !important;
}

.ifop-logo {
  width: 4rem;

  @media (max-width: 959.98px) {
    width: 3rem;
  }
}

.title-page {
  display: flex;
  flex-direction: column;
  color: white;
  &--top {
    font-size: 1.3em;
    border-bottom: 1px solid white;
  }
  &--bottom {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 599.98px) {
    display: none;
  }

  @media (max-width: 959.98px) {
    &--top {
      font-size: 1em;
      border-bottom: 1px solid white;
    }
    &--bottom {
      font-size: 0.6em;
    }
  }
}

#menu-nav-btn {
  max-width: 40vw;
  position: relative;
  .v-btn__content {
    max-width: 100%;
    //width: 100%;
    .text-truncate {
      //max-width: 100%;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.noselect {
  user-select: none;
}

.container.container--fit {
  max-width: 80%;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.w-100 {
  width: 100%;
  min-width: 100%;
}
.max-w-100 {
  max-width: 100%;
}
</style>
