import Vue from "vue";
import App from "./vue/App.vue";
import store from "./vue/store";
import vuetify from "./vue/plugins/vuetify";
import router from "./vue/router";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import { Amplify } from "aws-amplify";
import { awsconfig } from "./aws-config";

Vue.config.productionTip = false;

Amplify.configure(awsconfig);


applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
